import React, { useEffect, useState } from 'react';
import HomeArticles from './LatestArticles';
import TopCategories from './TopCategories';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function Home() {
  const [heading, setHeading] = useState('');
  useEffect(() => {
    setHeading(
      `"कुछ पढ़िए कुछ लिखिए ज़िन्दगी में कुछ एहसान खुद पर भी कीजिए
 फ़क़त किस्से कहानी नहीं यहाँ पर ज़िन्दगी के कुछ तजुर्बे पढ़िए "`
    );
  }, []);
  return (
    <div className="homepage">
      <Helmet>
        <title>
          Lekh - Lets do the word dance in your mind one of the finest website
          for all type of news, life experience, knowledge and of course gossip.
        </title>
      </Helmet>
      <div className="home-canvas">
        <h1 className="home-heading">{heading}</h1>
        <img src="/images/canvas.jpg" alt="cat" />
      </div>
      <Container className="my-4">
        <TopCategories />
        <HomeArticles />
      </Container>
    </div>
  );
}

export default Home;
